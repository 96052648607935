<template>
  <tr>
    <td class="popup-icon" :class="this.name">
      <font-awesome-icon :icon="this.icon"/>
    </td>
    <td class="popup-text">{{ this.value }} {{ this.unit }}</td>
  </tr>
</template>

<script>
import Vue from 'vue';

// FontAwsome
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faPhone,
  faPhoneVolume,
  faPhoneSlash,
  faPowerOff,
  faCarBattery,
  faSolarPanel,
  faLock,
  faLockOpen,
  faThermometerHalf
} from '@fortawesome/free-solid-svg-icons'

library.add(faPhone, faPhoneVolume, faPhoneSlash, faPowerOff, faCarBattery, faSolarPanel, faLock, faLockOpen, faThermometerHalf)

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

export default {
  name: 'TooltipRow',
  components: {},
  data() {
    return {}
  },
  props: {
    icon: null,
    name: null,
    value: null,
    unit: null
  },
  methods: {},
  mounted() {
  },
}
</script>

<style scoped>
.popup-icon {
  text-align: center;
  font-size: 24px;
  color: #666;
}

.popup-icon.success {
  color: #77af00;
}

.popup-icon.warning {
  color: #ea9500;
}

.popup-icon.danger {
  color: #d92e00;
}

.popup-text {
  text-align: left;
  padding-left: 10px;
}
</style>