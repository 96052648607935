<template>
  <div>
    <b-sidebar id="sidebar" v-model="sidebarVisible" title="Zdarzenia" right shadow>
      <b-container>
        <EventAlert
            v-for="(event) in urgentEvents"
            :variant="event.variant"
            :event="event"
            :key="event.id"
            :dismissible="false"
            @fitBounds="fitBoundsEvent"
        />
        <EventAlert
            v-for="(event) in events"
            :variant="event.variant"
            :event="event"
            :key="event.id"
            :dismissible="true"
            @eventDone="eventDone"
            @fitBounds="fitBoundsEvent"
        />
      </b-container>
    </b-sidebar>

    <div class="map-bg" id="map-layer" :class="getTooltipClass()">
      <l-map
          ref="myMap"
          v-if="showMap"
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          style="height: 100%"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
          @ready="mapReady()"
      >
        <l-control :position="'topleft'">
          <b-button variant="light" @click="fitBounds()" class="leaflet-control-layers">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
          </b-button>
        </l-control>

        <l-control>
          <b-button variant="light" @click="openSidebar()" class="leaflet-control-layers">
            <font-awesome-icon icon="fa-solid fa-bars"/>
          </b-button>
        </l-control>

        <l-control>
          <b-button variant="light" @click="readyTest()" class="leaflet-control-layers">
            <font-awesome-icon icon="fa-solid fa-power-off"/>
          </b-button>
        </l-control>

        <l-layer-group ref="markersGroup">
          <l-marker
              @click="fitBounds(item)"
              v-for="item in items"
              :key="item.Transmitter.id"
              :icon="item.Transmitter.icon"
              :lat-lng="getLatLng(item)"
          >
            <l-tooltip :options="item.Transmitter.tooltipOptions">
              <div>
                <table class="leaflet-tooltip-table" :class="item.Transmitter.tooltipOptions.permanent">
                  <tbody class="">

                  <TooltipHeader :name="item.Transmitter.name" :data-created="getDataCreated(item)" :data-km="getAttributeValue(item, 'km')"/>
                  <TooltipRow :name="item.icons.phone.color" :icon="item.icons.phone.type"/>
                  <!--TooltipRow :name="item.icons.power" :icon="'fa-solid fa-power-off'" /-->
                  <TooltipRow
                      v-if="getTransmitterType(item) === 'master'"
                      :name="item.icons.battery"
                      :value="getValueBattery(item)"
                      :unit="getUnitBattery(item)"
                      :icon="'fa-solid fa-car-battery'"
                  />
                  <TooltipRow
                      v-if="getTransmitterType(item) === 'master'"
                      :name="item.icons.solarpanel"
                      :value="getValueSolarPanel(item)"
                      :unit="getUnitSolarPanel(item)"
                      :icon="'fa-solid fa-solar-panel'"
                  />
                  <TooltipRow :name="item.icons.lock" :icon="getIconLock(item)"/>
                  <TooltipRow
                      :name="item.icons.thermometer"
                      :value="getValueThermometer(item)"
                      :unit="getUnitThermometer(item)"
                      :icon="'fa-solid fa-thermometer-half'"
                  />
                  </tbody>
                </table>
              </div>
            </l-tooltip>
          </l-marker>
        </l-layer-group>
        <l-tile-layer
            :url="url"
            :attribution="attribution"/>
      </l-map>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import config from "../../config"
import moment from 'moment';
import {JSONPath} from 'jsonpath-plus';
import sortObjectsArray from 'sort-objects-array';

// Leaflet
import L from 'leaflet';
import {LControl, LLayerGroup, LMap, LMarker, LTileLayer, LTooltip} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import {library} from '@fortawesome/fontawesome-svg-core'
import {faBars, faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import SocketIO from "socket.io-client";
import VueSocketIO from 'vue-socket.io';
import TooltipHeader from "@/components/tooltip/Header.vue";
import TooltipRow from "@/components/tooltip/Row.vue";
import EventAlert from "@/components/sidebar/EventAlert.vue";

export const mapboxToken = process.env.MAPBOX_TOKEN ? process.env.MAPBOX_TOKEN : config.MAPBOX_TOKEN;

library.add(faMagnifyingGlass, faBars);

// Socket IO
export const ioAddress = process.env.IO_ADDRESS ? process.env.IO_ADDRESS : config.IO_ADDRESS;
export const ioNamespace = 'client';
// export const ioRoom = 'admin';

Vue.use(new VueSocketIO({
  debug: false,
  connection: SocketIO(ioAddress + "/" + ioNamespace, {
    path: '/socket.io/'
  }),
}))

export default {
  name: 'App',
  components: {
    TooltipHeader,
    TooltipRow,
    EventAlert,
    LControl,
    LMap,
    LTileLayer,
    LMarker,
    LLayerGroup,
    LTooltip,
  },
  data() {
    return {
      map: null,
      fitMap: true,
      zoom: 13,
      center: L.latLng(47.41322, -1.219482),
      url: `https://api.mapbox.com/styles/v1/mapbox/light-v9/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`,
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
          'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      withPopup: L.latLng(47.41322, -1.219482),
      withTooltip: L.latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: L.latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,
      socket: null,
      zoomClass: 'zoom-13',
      items: {},
      events: [],
      urgentEvents: [],
      oldEvents: [],
      iconFullSize: {
        width: 151,
        height: 334
      },
      iconSize: 6,
      icons: {
        transmitter: {
          master: {
            success: {
              iconUrl: "/img/master_success.png",
//                            iconSize: [45, 100],
//                             iconAnchor: [0, 0]
            },
            warning: {
              iconUrl: "/img/master_warning.png"
            },
            danger: {
              iconUrl: "/img/master_danger.png"
            }
          },
          slave: {
            success: {
              iconUrl: "/img/slave_success.png"
            },
            warning: {
              iconUrl: "/img/slave_warning.png"
            },
            danger: {
              iconUrl: "/img/slave_danger.png"
            }
          }
        }
      },
      fields: {
        phone: 'PHONESTAT',
        battery: 'AD2',
        solarpanel: 'AD3',
        lock: 'DI2',
        thermometer: 'T0'
      },
      transmitterTimeout: 60 * 60 * 24,
      sidebarVisible: false,
      eventIds: []
    }
  },
  props: {
    socketRoom: null,
    groupId: null
  },
  created: function () {
    setInterval(this.setItems, 60000);
  },
  sockets: {
    connect: function () {
      console.log(`Socket connected to ${ioAddress}!`);
      // console.log(this.$socket);
      this.$socket.emit('room', JSON.stringify({
        event: 'getData',
        socketRoom: this.socketRoom,
        groupId: this.groupId
      }));
    },

    // Fired when the server sends something on the "items" channel.
    items(items) {
      console.log(items);
      if (items.groupId !== this.groupId) {
        return;
      }
      for (let id in items.data) {
        items.data[id].Transmitter.tooltipOptions = {};
      }
      // console.log(items);
      this.$set(this, 'items', items.data);
      this.setItems();
      if (this.fitMap) {
        this.fitBounds();
        this.fitMap = false;
      }
      this.setEvents();
    },

    item(items) {
      // console.log(items);
      for (let id in items.data) {
        // Check if transmitter belongs to group
        if (this.items[id] === undefined) {
          continue;
        }
        items.data[id].Transmitter.tooltipOptions = {};
        this.$set(this.items, id, items.data[id]);
        this.setItem(id);
      }
      this.setEvents();
    }
  },
  methods: {
    openSidebar() {
      this.sidebarVisible = true;
      // this.$root.$emit('bv::toggle::collapse', 'sidebar');
    },
    readyTest() {
      this.$socket.emit('event', JSON.stringify({
        queueName: 'event-handler-frontend-events',
        routingKey: 'frontend-event',
        data: {event: 'phonesTest'},
        socketRoom: this.socketRoom,
        groupId: this.groupId
      }));
      // this.$root.$emit('bv::toggle::collapse', 'sidebar');
    },
    mapReady() {
      this.map = this.$refs.myMap.mapObject;
      console.log(this.items);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
      this.setItems();
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    fitBoundsEvent(event) {
      let item = this.items[event.model_id];
      this.fitBounds(item);
    },
    fitBounds(item = null) {
      let latLng = [];
      if (this.items.length === 0) {
        return;
      }
      if (item == null) {
        for (let key in this.items) {
          latLng.push(this.getLatLng(this.items[key]));
        }
      } else {
        latLng.push(this.getLatLng(item));
      }
      let bounds = new L.LatLngBounds(latLng).pad(0.2);
      this.map.fitBounds(bounds);
    },
    getLatLng(item) {
      return {
        lat: item.Transmitter.lat,
        lng: item.Transmitter.lng
      };
    },
    getDataCreated(item) {
      let dateTime = item.TransmittersData.created;
      // console.log(dateTime);
      if (typeof dateTime == 'undefined') {
        return null;
      }
      return new moment(dateTime);
    },
    getItemCreated(item) {
      let dateTime = item.TransmittersData.created;
      // console.log(dateTime);
      if (typeof dateTime == 'undefined') {
        return null;
      }
      return new moment(dateTime);
    },
    getPassedTime(item) {
      let dataDateTime = this.getItemCreated(item);
      // console.log(dataDateTime);
      if (dataDateTime == null) {
        dataDateTime = -1;
      }
      let timeDiff = moment().diff(dataDateTime, 'seconds');
      // console.log(timeDiff);
      return timeDiff;
    },

    // Attributes
    getAttributeValue(item, field, attr = 'value') {
      const value = JSONPath({path: '$.AttributesValue.' + field + '.' + attr, json: item});
      return value[0];
    },

    // Field
    getFieldValue(item, field, attr = 'value') {
      let value = JSONPath({path: '$.TransmittersInputsField.' + field + '.' + attr, json: item});
      return value[0];
    },
    getFieldOption(item, field, attr = 'value') {
      const value = JSONPath({
        path: '$.TransmittersInputsField.' + field + '.InputField.InputFieldsOption.' + attr,
        json: item
      });
      return value[0];
    },
    getIconColorMinMax(item, field, aboveMax = 'danger', betweenMinMax = 'success', belowMin = 'danger') {
      const isValueValid = this.getFieldValue(item, field, 'is_valid');
      if (isValueValid === true) {
        return betweenMinMax;
      }
      const value = this.getFieldValue(item, field);
      // console.log(value);
      if (typeof value === 'undefined') {
        return '';
      }
      const minValue = this.getFieldOption(item, field, 'min_value');
      // console.log(minValue);
      const maxValue = this.getFieldOption(item, field, 'max_value');
      // console.log(maxValue);
      if (value > maxValue) {
        return aboveMax;
      } else if (value < minValue) {
        return belowMin;
      }
      return betweenMinMax;
    },

    getTransmitterName(item) {
      let value = JSONPath({
        path: '$.Transmitter.name',
        json: item
      });
      return value[0];
    },

    getFieldName(item, symbol) {
      let value = JSONPath({
        path: `$.TransmittersInputsField.${symbol}.name`,
        json: item
      });
      return value[0];
    },

    getInputFieldUnit(item, symbol) {
      let value = JSONPath({
        path: `$.TransmittersInputsField.${symbol}.unit`,
        json: item
      });
      return value[0];
    },

    // Transmitter
    getTransmitterType(item) {
      return this.getAttributeValue(item, 'type');
    },
    getTransmitterIcon(item) {
      let results = [];
      results.push(this.getIconColorPower(item, true));
      results.push(this.getIconColorBattery(item));
      results.push(this.getIconColorSolarPanel(item));
      results.push(this.getIconColorLock(item));
      results.push(this.getIconColorThermometer(item));
      let type = this.getAttributeValue(item, 'type');
      if (type == null) {
        type = 'slave';
      }
      let icon = this.icons.transmitter[type].success;
      results.forEach(result => {
        if (result !== 'success') {
          icon = this.icons.transmitter[type].danger;
          return;
        }
      });
      let zoomPercent = this.currentZoom / 20 * 100;
      // console.log(zoomPercent);
      let width = this.iconFullSize.width * zoomPercent / 100 / 2;
      let height = this.iconFullSize.height * zoomPercent / 100 / 2;
      icon.iconSize = [
        width,
        height
      ];
      icon.iconAnchor = [width / 2 - 5, height * 0.9];
      // console.log(icon);
      return L.icon(icon);
    },

    // Phone
    getIconTypePhone(item) {
      let value = this.getFieldValue(item, this.fields.phone);
      if (typeof value === 'undefined') {
        return 'fa-phone ';
      }
      switch (parseInt(value)) {
        case 0:
          return 'fa-phone';
        case 1:
          return 'fa-phone';
        case 2:
          return 'fa-phone-volume';
        case 3:
          return 'fa-phone-volume';
        case 4:
          return 'fa-phone';
        case 5:
          return 'fa-phone';
        case 6:
          return 'fa-phone-volume';
        case 7:
          return 'fa-phone';
        case 8:
          return 'fa-phone';
        case 9:
          return 'fa-phone-slash';
        case 10:
          return 'fa-phone-slash';
        case 11:
          return 'fa-phone';
      }
      return 'fa-phone';
    },
    getIconColorPhone(item) {
      // console.log(this.fields.phone);
      let value = this.getFieldValue(item, this.fields.phone);
      let classVal = this.getIconTypePhone(item);

      if (typeof value === 'undefined') {
        return false;
      }
      switch (parseInt(value)) {
        case 0:
          return `danger ${classVal} down`;
        case 1:
          return `success ${classVal} down`;
        case 2:
          return `warning ${classVal} up`;
        case 3:
          return `success ${classVal} up`;
        case 4:
          return `success ${classVal} down`;
        case 5:
          return `warning ${classVal}`;
        case 6:
          return `success ${classVal} up`;
        case 7:
          return `success ${classVal} down`;
        case 8:
          return `success ${classVal} down`;
        case 9:
          return `danger ${classVal} down`;
        case 10:
          return `danger ${classVal} down`;
        case 11:
          return `danger ${classVal} down`;
      }

      return 'warning phone';
    },

    // Power
    getIconColorPower(item, pushEvent = false) {
      let passedTime = this.getPassedTime(item);
      if (passedTime < 0) {
        return false;
      }
      // console.log(passedTime);
      if (passedTime > this.transmitterTimeout) {
        // console.log(item);
        if (pushEvent) {
          let event = this.createUrgentEvent(item, 'danger', 'Brak komunikacji sieciowej');
          this.urgentEvents.push(event);
        }
        return 'danger';
      }
      return 'success';
    },

    // Battery
    getValueBattery(item) {
      return this.getFieldValue(item, this.fields.battery);
    },
    getUnitBattery(item) {
      return this.getFieldOption(item, this.fields.battery, 'unit');
    },
    getIconColorBattery(item) {
      if (this.getTransmitterType(item) !== 'master') {
        return 'success';
      }
      return this.getIconColorMinMax(item, this.fields.battery);
    },

    // Solar panel
    getValueSolarPanel(item) {
      return this.getFieldValue(item, this.fields.solarpanel);
    },
    getUnitSolarPanel(item) {
      return this.getFieldOption(item, this.fields.solarpanel, 'unit');
    },
    getIconColorSolarPanel(item) {
      if (this.getTransmitterType(item) !== 'master') {
        return 'success';
      }
      return this.getIconColorMinMax(item, this.fields.solarpanel);
    },

    // Lock
    getIconColorLock(item) {
      let value = this.getFieldValue(item, this.fields.lock);
      if (typeof value === 'undefined') {
        return false;
      }
      return parseInt(value) === 1 ? 'success' : 'danger';
    },
    getIconLock(item) {
      let value = this.getFieldValue(item, this.fields.lock);
      if (typeof value === 'undefined') {
        return 'fa-lock';
      }
      return parseInt(value) === 1 ? 'fa-lock' : 'fa-lock-open';
    },

    // Thermometer
    getValueThermometer(item) {
      return this.getFieldValue(item, this.fields.thermometer);
    },
    getUnitThermometer(item) {
      return this.getFieldOption(item, this.fields.thermometer, 'unit');
    },
    getIconColorThermometer(item) {
      return this.getIconColorMinMax(item, this.fields.thermometer, 'danger', 'success', 'danger')
    },
    getTooltipOptions(item) {
      let type = item.AttributesValue.type.value;
      let direction = 'left';
      let offset = [-20, 0];
      switch (type) {
        case 'master':
          offset = [20, 0];
          direction = 'right';
          break;
      }

      let permanent = true; // this.currentZoom > 13;

      return {permanent: permanent, interactive: true, direction: direction, opacity: 0.9, offset: offset};
    },
    getTooltipClass() {
      return this.currentZoom > 14 ? 'tooltips-visible' : 'tooltips-hidden';
    },
    setItem(id) {
      let item = this.items[id];
      if (typeof item === 'undefined') {
        return false;
      }
      // console.log(this.getTooltipOptions(item));
      item.Transmitter.tooltipOptions = this.getTooltipOptions(item);
      this.$set(item.Transmitter, 'icon', this.getTransmitterIcon(item));
      this.$set(item, 'icons', {
        phone: {
          type: this.getIconTypePhone(item),
          color: this.getIconColorPhone(item)
        },
        power: this.getIconColorPower(item),
        battery: this.getIconColorBattery(item),
        solarpanel: this.getIconColorSolarPanel(item),
        lock: this.getIconColorLock(item),
        thermometer: this.getIconColorThermometer(item)
      });
      this.items[id] = item;
      return this.items[id];
    },
    setItems() {
      this.urgentEvents = [];
      let items = this.items;
      for (let id in items) {
        this.setItem(id);
      }
    },
    createUrgentEvent(item, variant, content) {
      let created = this.getItemCreated(item);
      if (created != null) {
        created.add(this.transmitterTimeout, 'seconds');
      }
      return {
        created: created,
        model_id: item.Transmitter.id,
        transmitter_name: this.getTransmitterName(item),
        variant: variant,
        content: content,
      };
    },
    setEvents() {
      let events = this.getEvents(this.items);
      for (let i in events) {
        let transmitterId = events[i].model_id;
        let transmitter = this.items[transmitterId];
        events[i].transmitter_name = this.getTransmitterName(transmitter);
        let variant = 'light';
        let content = '';
        let value, min, max, fieldName, unit;
        if (events[i].data != null) {
          let data = JSON.parse(events[i].data);
          value = data.value;
          min = Math.round(data.min);
          max = Math.round(data.max);
          fieldName = this.getFieldName(transmitter, data.symbol);
          unit = this.getInputFieldUnit(transmitter, data.symbol);
        }
        switch (events[i].symbol) {
          case 'incomingCall':
            variant = 'warning';
            content = 'Połączenie przychodzące';
            break;
          case 'missedCall':
            variant = 'danger';
            content = 'Nieodebrane połączenie';
            break;
          case 'boolEvent':
            // console.log(events[i].data.symbol)
            variant = 'danger';
            content = fieldName;
            break;
          case 'belowMinEvent':
            variant = 'danger';
            content = `${fieldName} ${value}${unit} poniżej minimum (${min}${unit})`;
            break;
          case 'aboveMaxEvent':
            variant = 'danger';
            content = `${fieldName} ${value}${unit} powyżej maksimum (${max}${unit})`;
            break;
        }
        events[i].variant = variant;
        events[i].content = content;
        // console.log(events[i]);
      }
      this.events = events;
      // console.log(this.events);

      if (this.events.length > 0 || this.urgentEvents.length > 0) {
        this.sidebarVisible = true;
      }
      this.newEvents();
    },
    getEvents(items) {
      let events = [];
      for (let i in items) {
        for (let event in items[i].ModelEvent) {
          let e = items[i].ModelEvent[event];
          events.push(e);
        }
      }

      return sortObjectsArray(events, 'created', 'desc');
    },
    newEvents() {
      let newEvents = this.getEvents(this.items);
      let oldEvents = [];
      let fitItem = null;
      for (let i in newEvents) {
        let id = newEvents[i].id;
        oldEvents[id] = newEvents[i];
        let item = this.items[newEvents[i].model_id];
        if (this.oldEvents[id] === undefined && item !== undefined) {
          fitItem = item;
        }
      }
      if (fitItem) {
        this.fitBounds(fitItem);
      }
      this.oldEvents = oldEvents;
    },
    eventDone(event) {
      this.$socket.emit('event', JSON.stringify({
        queueName: 'event-handler-frontend-events',
        routingKey: 'frontend-event',
        data: {event: 'modelEventDone', data: event},
        socketRoom: this.socketRoom,
        groupId: this.groupId
      }));
      // console.log(event);
    }
  },
  watch: {},
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  }
}
</script>

<style>
.map-bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
}

.leaflet-tooltip-table {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10pt;
}

.leaflet-tooltip-table td {
  transition: transform 2s;
  padding: 0;
}

.leaflet-tooltip .popup-icon {
  padding: 0 5px;
  transition: transform 2s;
}

.leaflet-tooltip .popup-text {
  display: none;
  transition: transform 2s;
}

.leaflet-tooltip:hover {
  z-index: 1000;
}

.leaflet-tooltip:hover .popup-text {
  display: table-cell;
}

.leaflet-tooltip-left {
  margin-left: -25px !important;
}

.leaflet-tooltip-right {
  margin-left: 25px !important;
}

.tooltips-hidden .leaflet-tooltip-pane * {
  cursor: default !important;
  opacity: 0 !important;
}

.fa-phone.down svg {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.fa-phone.up svg {
  transform: rotate(90deg);
}

.fa-phone-volume.up svg {
  transform: rotate(-45deg);
  font-size: xx-large;
}

img.leaflet-marker-icon {
  cursor: pointer;
}

</style>
