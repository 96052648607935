<template>
  <b-alert show :variant="variant || 'light'" :dismissible="dismissible" @dismissed="eventDone()" fade>
    <b-row v-show="event.created">
      <b-col>
        <small>
          <font-awesome-icon icon="fa-solid fa-clock" class="clock"/>
          {{ this.created }}</small>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6 class="item-name" @click="fitBounds()">{{ event.transmitter_name }}</h6>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="content" style="margin-bottom: 0;">{{ event.content }}</p>
      </b-col>
    </b-row>
  </b-alert>
</template>

<script>

import moment from 'moment';

import {
  faClock
} from '@fortawesome/free-solid-svg-icons'

import {library} from "@fortawesome/fontawesome-svg-core";

library.add(faClock)

export default {
  name: 'EventAlert',
  components: {},
  data() {
    return {}
  },
  props: {
    variant: null,
    event: null,
    dismissible: null
  },
  methods: {
    eventDone: function () {
      this.$emit("eventDone", this.event);
    },
    fitBounds: function () {
      this.$emit("fitBounds", this.event);
    }
  },
  computed: {
    created: function() {
      let created = this.event.created;
      if (created == null) {
        return null;
      }
      if (typeof created !== 'object') {
        created = new moment(created);
      }

      return created.format('DD.MM.YYYY HH:mm:ss');
    }
  }
}
</script>

<style scoped>
.item-name {
  cursor: pointer;
}

h6 {
  margin-top: 5px;
}

.clock {
  margin-right: 3px;
}

p.content {
  font-size: 14px;
}
</style>