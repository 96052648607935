<template>
  <tr>
    <td colspan="2" class="header">
      <strong>{{name}}<br/>{{dataKm}}</strong>
      <p style="font-size: 11px; line-height: 1; margin: 0;">
        {{dataCreated != null ? dataCreated.format('DD.MM.Y') : ''}}<br/>
        {{dataCreated != null ? dataCreated.format('HH:mm:ss') : ''}}
      </p>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'TooltipHeader',
  components: {},
  data() {
    return {}
  },
  props: {
    name: null,
    dataCreated: null,
    dataKm: null
  },
  methods: {},
  mounted() {
  },
}
</script>

<style scoped>
.header {
  padding-bottom: 5px;
}
</style>