<template>
  <div id="app">
    <Map v-if="socketRoom" :socket-room="socketRoom" :group-id="groupId"></Map>
  </div>
</template>

<script>
import Vue from 'vue';

import Map from "@/components/Map.vue";

// Bootstrap
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';

Vue.use(BootstrapVue, IconsPlugin);
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
  name: 'App',
  components: {
    Map,
  },
  data() {
    return {
      socketRoom: null,
      groupId: null,
    }
  },
  methods: {
    receiveMessage(event) {
      if (typeof event.data.Map === "undefined") {
        // not a settings message
        // console.log('Invalid message!');

        return;
      }
      console.log(event.data);
      this.socketRoom = event.data.Client.socket_room;
      this.groupId = event.data.DevicesGroup.id;

      console.log(`Socket room: ${this.socketRoom}`);
      console.log(`Group ID: ${this.groupId}`);
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage);
  },
}
</script>

<style>
#app {
  background-color: white;
}
</style>
